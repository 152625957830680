window.onload = function () {
    if (document.getElementById('gclid') !== null && readCookie("gclid") != null) {
        document.getElementById('gclid').value = readCookie('gclid');
    }

    if (document.getElementById('pardot-form_sfcampaignid') !== null && readCookie("sfcampaignid") != null) {
        document.getElementById('pardot-form_sfcampaignid').value = readCookie('sfcampaignid');
    }

    if (document.getElementById('pardot-form_kpid') != null && readCookie("kpid") != null) {
        document.getElementById('pardot-form_kpid').value = readCookie('kpid');
    }

    if (document.getElementById('pardot-form_msclkid') != null && readCookie("msclkid") != null) {
        document.getElementById('pardot-form_msclkid').value = readCookie('msclkid');
    }

    if (document.getElementById('pardot-form_liid') != null && readCookie("li_fat_id") != null) {
        document.getElementById('pardot-form_liid').value = readCookie('li_fat_id');
    }

    if (document.getElementById('pardot-form_utm-source') != null && readCookie("utm_source") != null) {
        document.getElementById('pardot-form_utm-source').value = readCookie("utm_source");
    }
    
    if (document.getElementById('pardot-form_utm-medium') != null && readCookie("utm_medium") != null) {
        document.getElementById('pardot-form_utm-medium').value = readCookie("utm_medium");
    }
    
    if (document.getElementById('pardot-form_utm-campaign') != null && readCookie("utm_campaign") != null) {
        document.getElementById('pardot-form_utm-campaign').value = readCookie("utm_campaign");
    }

    if (document.getElementById('pardot-form_utm-content') != null && readCookie("utm_content") != null) {
        document.getElementById('pardot-form_utm-content').value = readCookie("utm_content");
    }
}